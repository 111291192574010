export const configureConfigHeader = (getState) => {
  const token = getState().auth.access_token

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
}
