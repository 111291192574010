import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { CLOSE_ALERT } from '../../redux/actions/types.js';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  alert: {
    borderRadius: '10px',
    fontSize: theme.typography.pxToRem(18),
  },
}));

function Alert(props) {
  const classes = useStyles();
  return (
    <MuiAlert
      className={classes.alert}
      elevation={6}
      variant="filled"
      {...props}
    />
  );
}

export default function CustomAlerts(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { snackbarOpen, snackbarMessage, snackbarType } = useSelector(
    (state) => ({
      snackbarOpen: state.alert.snackbarOpen,
      snackbarMessage: state.alert.snackbarMessage,
      snackbarType: state.alert.snackbarType,
    }),
    shallowEqual
  );

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({
      type: CLOSE_ALERT,
      payload: { snackbarOpen: false },
    });
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          classes={{ root: classes.alert }}
          onClose={handleClose}
          severity={snackbarType}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
