import React, { memo } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import history from 'utils/history';
import PrivateRoutes from './PrivateRoutes';
import Auth from './Auth';

function Routes() {
	return (
		<BrowserRouter>
			<Switch>
				<Route path="/app">
					<PrivateRoutes />
				</Route>

				<Route path="">
					<Auth />
				</Route>
			</Switch>
		</BrowserRouter>
	)
}

export default memo(Routes);