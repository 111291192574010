import {
  LOGIN_SUCCESS,
  LOGOUT,
  LOAD_USER_FROM_STORAGE,
  USER_LOADING,
  SET_LANGUAGE,
} from '../actions/types'


const initialState = {
  access_token: null,
  isAuthenticated: false,
  isLoading: false,
  customer: null,
  permissions: [],
  roles: [],
  language: 'en'
}

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true
      }

    case LOGIN_SUCCESS:
      return {
        access_token: action.payload.access_token,
        isAuthenticated: true,
        isLoading: false,
        customer: {
          ...action.payload.customer
        },
        permissions: action.payload.tenant_employee_permissions !== undefined ? action.payload.tenant_employee_permissions : [],
        roles: action.payload.roles !== undefined ? action.payload.roles : [],
      }

    case LOAD_USER_FROM_STORAGE:
      return {
        access_token: action.payload.user.access_token,
        isAuthenticated: true,
        isLoading: false,
        customer: {
          ...action.payload.user.customer
        },
        permissions: action.payload.user.permissions,
        roles: action.payload.user.roles
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case LOGOUT:
      localStorage.removeItem('user')
      return {
        access_token: null,
        isAuthenticated: false,
        isLoading: false,
        customer: null,
        permissions: [],
        roles: []
      }

    default:
      return state
  }
}
