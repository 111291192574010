import React, { memo } from 'react';
import { Redirect } from 'react-router-dom';
import PublicRoutes from './PublicRoutes';
import { useSelector } from 'react-redux'

function Auth() {
    const auth = useSelector((state) => state.auth)

    if (auth.isAuthenticated){
        return <Redirect to="/app" />
    }else{
        return <PublicRoutes />
    }
}

export default memo(Auth);
