import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

import LoginPage from 'views/LoginPage/LoginPage.js'

function PublicRoutes() {
	return (
		<Fragment>
			<Switch>
				<Route path="/login">
					<LoginPage />
				</Route>

				<Route path="/">
				    <LoginPage />
				</Route>

			</Switch>
		</Fragment>
	)
}

export default PublicRoutes;
