import {
  LOGIN_SUCCESS,
  LOGOUT,
  LOAD_USER_FROM_STORAGE,
  USER_LOADING,
  ALERT_FAIL,
  ALERT_SUCCESS,
  SET_LANGUAGE,
} from './types'
import { ServerConfig } from 'config'
import { configureConfigHeader } from 'utils/utils.js'
import axios from 'axios'

export const login = (email, password) => (dispatch, getState) => {
  dispatch({
    type: USER_LOADING
  })
  const config = configureConfigHeader(getState)
  const body = JSON.stringify({ email, password })
  axios
    .post(`${ServerConfig.SERVER_URL}/auth/`, body, config)
    .then((res) => {
      console.log("-----------------------")
      console.log(res.data)
      console.log("-----------------------")

      if(res.data.tenant_employee_permissions === undefined){
        dispatch({
          type: ALERT_FAIL,
          payload: 'No permission'
        })
      }else{
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        })
      }
    })
    .catch((err) => {
      console.log(err)
      dispatch({
        type: ALERT_FAIL,
        payload: 'Incorrect credentials.'
      })
    })
}

export const loadUserFromStorage = () => (dispatch) => {
  console.log('Checking if user data in local storage')
  try {
    const storedUser = JSON.parse(localStorage.getItem('user'))
    console.log('Found a user in local storage')
    console.log(storedUser)
    if (storedUser.user.access_token) {
      console.log('The user has an access token!')
      dispatch({
        type: LOAD_USER_FROM_STORAGE,
        payload: storedUser
      })
    }
  } catch (err) {
    console.log(err)
  }
}

export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT
  })
}

export const setLanguage = (language) => (dispatch) => {
  dispatch({
    type: SET_LANGUAGE,
    payload: language,
  });
};
