// TODO: New name for this reducer. Customer field exists in auth reducer

import {
  UNIQUE_CUSTOMER,
  NOT_UNIQUE_CUSTOMER,
  CUSTOMER_SMS_SENT,
  CUSTOMER_CODE_VERIFIED,
  CHECKING_CUSTOMER_CODE,
  CUSTOMER_2FA_EMAIL_SENT,
} from "../actions/types.js";

const initialState = {
  uniqueCreds: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case UNIQUE_CUSTOMER:
      return {
        ...state,
        uniqueCreds: true,
      };
    case NOT_UNIQUE_CUSTOMER:
      return {
        ...state,
        uniqueCreds: false,
      };
    case CUSTOMER_SMS_SENT:
      return {
        ...state,
        SMSCodeSent: true,
      };
    case CUSTOMER_2FA_EMAIL_SENT:
      return {
        ...state,
        email2FASent: true,
      };
    case CUSTOMER_CODE_VERIFIED:
      return {
        ...state,
        verifyingCode: false,
        codeVerified: true,
      };
    case CHECKING_CUSTOMER_CODE:
      return {
        ...state,
        verifyingCode: true,
      };
    default:
      return state;
  }
}
