import { Roles } from 'config'

import CashoutPage from 'views/CashoutPage/CashoutPage.js'

export default [
{
  component: CashoutPage,
  path: '/',
  title: 'Cashout',
  exact: true,
  permission: []
},
]
