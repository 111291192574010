import React, { memo } from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { logout } from 'redux/actions/auth'
import Menu from '@material-ui/icons/Menu'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import { NavLink } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Hidden,
  Drawer,
  IconButton
} from '@material-ui/core'
import logo from 'assets/img/logo/brandSignature.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: 'none',
  },
  appBarTitle: {
    flexGrow: 1
  },
  toolbar: {
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  title: {
    flexGrow: 1
  },
  logo: {
    width: '12rem',
    [theme.breakpoints.down('xs')]: {
      width: '10rem'
    }
  },
   list: {
    margin: 0,
    paddingLeft: 0,
    listStyle: 'none',
    paddingTop: '0',
    paddingBottom: '0',
    color: 'inherit',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      width: '300px'
    }
  },
  listItem: {
    float: 'left',
    color: 'inherit',
    position: 'relative',
    display: 'block',
    width: 'auto',
    margin: '0',
    padding: '0 1rem',
    lineHeight: '.9rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      lineHeight: '0',
      float: 'none',
      '&:not(:last-child)': {
        borderRight: '0'
      }
    }
  },
  navLink: {
    fontWeight: 300,
    fontSize: '1rem',
    color: 'inherit',
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    position: 'relative',
    textDecoration: 'none',
    margin: '0px',
    display: 'inline-flex',
    border: 'none',
    padding: '0',
    lineHeight: '0',
    '&:hover,&:focus': {
      color: 'inherit',
      background: 'inherit'
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '-15px',
      left: 0,
      width: '100%',
      height: '2px',
      display: 'block',
      background: theme.palette.secondary.main,
      visibility: 'hidden',
      transform: 'scaleX(0)',
      transition: 'all 0.5s ease-in-out 0s'
    },
    '&:hover::after': {
      visibility: 'visible',
      transform: 'scaleX(1)'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 30px)',
      marginLeft: '15px',
      marginBottom: '8px',
      marginTop: '8px',
      textAlign: 'left',
      padding: '0.9375rem',
      '& > span:first-child': {
        justifyContent: 'flex-start'
      },
      '&:hover::after': {
        visibility: 'hidden'
      },
      '&:hover': {
        color: '#b3b2b2'
      }
    }
  },
  navLinkBold: {
    fontWeight: 500,
    fontSize: '1rem',
    color: 'inherit',
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    position: 'relative',
    textDecoration: 'none',
    margin: '0px',
    display: 'inline-flex',
    border: 'none',
    padding: '0',
    lineHeight: '0',
    '&:hover,&:focus': {
      color: 'inherit',
      background: 'inherit'
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '-15px',
      left: 0,
      width: '100%',
      height: '2px',
      display: 'block',
      background: theme.palette.secondary.main,
      visibility: 'hidden',
      transform: 'scaleX(0)',
      transition: 'all 0.5s ease-in-out 0s'
    },
    '&:hover::after': {
      visibility: 'visible',
      transform: 'scaleX(1)'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 30px)',
      marginLeft: '15px',
      marginBottom: '8px',
      marginTop: '8px',
      textAlign: 'left',
      padding: '0.9375rem',
      '& > span:first-child': {
        justifyContent: 'flex-start'
      },
      '&:hover::after': {
        visibility: 'hidden'
      },
      '&:hover': {
        color: '#b3b2b2'
      }
    }
  },
  active: {
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: '-15px',
      left: 0,
      width: '100%',
      height: '2px',
      display: 'block',
      background: theme.palette.secondary.main
    },
    [theme.breakpoints.down('sm')]: {
      color: '#b3b2b2',
      '&:before': {
        visibility: 'hidden'
      }
    }
  }
}))

function TopNav(props) {
 let history = useHistory();
 const auth = useSelector((state) => state.auth)
const dispatch = useDispatch()

 function handleLogout() {
    dispatch(logout());
    history.push('/');
 }

    const classes = useStyles()
    const [mobileOpen, setMobileOpen] = React.useState(false)
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

 return (
    <header className={classes.root}>
        <AppBar className={classes.appBar} position="fixed">
            <Toolbar className={classes.toolbar} style={{'text-align':'center'}}>
                <Typography className={classes.appBarTitle}>
                      <img className={classes.logo} src={logo} alt="Evtek Logo" />
                </Typography>
        </Toolbar>
      </AppBar>
    </header>
 );
}

TopNav.propTypes = {
 routes: PropTypes.arrayOf(
  PropTypes.shape({
   path: PropTypes.string.isRequired,
   title: PropTypes.string.isRequired
  })
 ).isRequired,
 prefix: PropTypes.string,
 className: PropTypes.string
};

TopNav.defaultProps ={
 prefix: '',
 className: ''
};

export default memo(TopNav);
