import auth from './auth'
import customer from './customer'
import alert from './alert'
import { combineReducers } from 'redux'

export const rootReducer = combineReducers({
  auth,
  customer,
  alert
})
