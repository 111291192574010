import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'

// A custom theme for this app
let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#7DA1CA',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#78D64B'
    },
    tertiary: {
      main: '#164E71'
    },
    grey: {
      main: '#E6E7E9'
    },
    background: {
      default: '#fff'
    },
    text: {
      primary: '#797777'
    },
    error: {
      main: '#ff3d00'
    }
  },
  typography: {
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
    h1: {
      fontSize: '1.5rem',
      fontWeight: '500',
      color:'#7DA1CA',
      lineHeight: '2rem',
      marginBottom:10
    },
    h2: {
      fontSize: '2rem',
      fontWeight: '600',
      lineHeight:'1.5rem',
      marginBottom:10
    },
    h3: {
      // fontFamily: 'Roboto',
      fontSize: '1rem',
      fontWeight: '300',
    },
    h4: {
      fontSize: '1rem',
      fontWeight: '300'
    },
    h5: {
      fontSize: '1rem',
      fontWeight: '300'
    },
    h6: {
      fontSize: '1rem',
      fontWeight: '500',
      textTransform: 'inherit'
    },
    body:{
        fontSize: '1.5rem',
        fontWeight: '300'
    },
    body2: {
      fontSize: '1.75rem',
      fontWeight: '300',
    },
    caption: {
      fontSize: '1.125rem',
      fontWeight: '400',
      lineHeight: '1.5',
      display: 'block'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '12px',
        backgroundColor: '#97D700',
        color: 'white',
        textAlign: 'center',
        textTransform: 'inherit',
        /*'&:hover': {
          backgroundColor: '#6a8cb9',
          color: 'white'
        }*/
      },
      text: {
        padding: '8px 36px',
        fontWeight: '600',
        fontSize: '12px'
      }
    }
  }
})

//theme = responsiveFontSizes(theme)

export default theme
