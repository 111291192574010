import { createStore, applyMiddleware } from 'redux'
import { rootReducer } from './reducers/'
import { loadState, saveState } from './localstorage'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { throttle } from 'lodash'


const persistedState = loadState()
const middleware = [thunk]

export const store = createStore(
  rootReducer,
  persistedState,
  composeWithDevTools(applyMiddleware(...middleware))
)

store.subscribe(() => {
  saveState({
    user: store.getState().auth
  })  
})

store.subscribe(
  throttle(() => {
    saveState({
      user: store.getState().auth
    })  
  }, 1000)
)