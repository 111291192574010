import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

// Material UI components
import {
  AppBar,
  Toolbar,
  Typography,
  Hidden,
  Drawer,
  IconButton
} from '@material-ui/core'

// Material UI Icons
import Menu from '@material-ui/icons/Menu'

// Components
import TopNav from 'components/common/TopNav'
import LanguageSelect from 'components/LanguageSelect/LanguageSelect';

// assets
import logo from 'assets/img/logo/brandSignature.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: 'none'
  },
  appBarTitle: {
    flexGrow: 1
  },
  toolbar: {
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    flexGrow: 1
  },
  logo: {
    width: '12rem',
    [theme.breakpoints.down('xs')]: {
      width: '10rem'
    }
  },
  list: {
    margin: 0,
    paddingLeft: 0,
    listStyle: 'none',
    paddingTop: '0',
    paddingBottom: '0',
    color: 'inherit',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      width: '300px'
    }
  },
  listItem: {
    float: 'left',
    color: 'inherit',
    position: 'relative',
    display: 'block',
    width: 'auto',
    margin: '0',
    padding: '0 1rem',
    lineHeight: '.9rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      lineHeight: '0',
      float: 'none',
      '&:not(:last-child)': {
        borderRight: '0'
      }
    }
  },
  navLink: {
    fontWeight: 300,
    fontSize: '1rem',
    color: 'inherit',
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    position: 'relative',
    textDecoration: 'none',
    margin: '0px',
    display: 'inline-flex',
    border: 'none',
    padding: '0',
    lineHeight: '0',
    '&:hover,&:focus': {
      color: 'inherit',
      background: 'inherit'
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '-15px',
      left: 0,
      width: '100%',
      height: '2px',
      display: 'block',
      background: theme.palette.secondary.main,
      visibility: 'hidden',
      transform: 'scaleX(0)',
      transition: 'all 0.5s ease-in-out 0s'
    },
    '&:hover::after': {
      visibility: 'visible',
      transform: 'scaleX(1)'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 30px)',
      marginLeft: '15px',
      marginBottom: '8px',
      marginTop: '8px',
      textAlign: 'left',
      padding: '0.9375rem',
      '& > span:first-child': {
        justifyContent: 'flex-start'
      },
      '&:hover::after': {
        visibility: 'hidden'
      },
      '&:hover': {
        color: '#b3b2b2'
      }
    }
  },
  navLinkBold: {
    fontWeight: 500,
    fontSize: '1rem',
    color: 'inherit',
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    position: 'relative',
    textDecoration: 'none',
    margin: '0px',
    display: 'inline-flex',
    border: 'none',
    padding: '0',
    lineHeight: '0',
    '&:hover,&:focus': {
      color: 'inherit',
      background: 'inherit'
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '-15px',
      left: 0,
      width: '100%',
      height: '2px',
      display: 'block',
      background: theme.palette.secondary.main,
      visibility: 'hidden',
      transform: 'scaleX(0)',
      transition: 'all 0.5s ease-in-out 0s'
    },
    '&:hover::after': {
      visibility: 'visible',
      transform: 'scaleX(1)'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 30px)',
      marginLeft: '15px',
      marginBottom: '8px',
      marginTop: '8px',
      textAlign: 'left',
      padding: '0.9375rem',
      '& > span:first-child': {
        justifyContent: 'flex-start'
      },
      '&:hover::after': {
        visibility: 'hidden'
      },
      '&:hover': {
        color: '#b3b2b2'
      }
    }
  },
  active: {
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: '-15px',
      left: 0,
      width: '100%',
      height: '2px',
      display: 'block',
      background: theme.palette.secondary.main
    },
    [theme.breakpoints.down('sm')]: {
      color: '#b3b2b2',
      '&:before': {
        visibility: 'hidden'
      }
    }
  }
}))


const Nav = () => {
  const classes = useStyles()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const navOptions = [
    {title: 'Login', path: '/'},
  ];

  return (
    <header className={classes.root}>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.appBarTitle}>
            <Link to="/">
              <img className={classes.logo} src={logo} alt="Evtek Logo" />
            </Link>
          </Typography>
          <LanguageSelect/>
	  {/*
          <Hidden smDown implementation="css">
            <div className={classes.links}>
              <TopNav routes={navOptions}/>
            </div>
          </Hidden>
          <div>
            <Hidden mdUp>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
              >
                <Menu />
              </IconButton>
            </Hidden>
            <Hidden mdUp implementation="js">
              <Drawer
                variant="temporary"
                anchor={'right'}
                open={mobileOpen}
                classes={{
                  paper: classes.drawerPaper
                }}
                onClose={handleDrawerToggle}
              >
                <div>
                  <Toolbar />
                  <TopNav routes={navOptions} />
                </div>
              </Drawer>
            </Hidden>
          </div>
	  */}
        </Toolbar>
      </AppBar>
    </header>
  )
}

export default Nav
