import { intersection } from 'lodash';
import { Roles } from 'config';
import { useSelector, useDispatch } from 'react-redux'

export function isArrayWithLength(arr) {
 return (Array.isArray(arr) && arr.length)
}


/*
 * map the user-role to allowed routes
 * Config for this is in config/PrivateRoutesConfig
 */
export function getAllowedRoutes(routes, user_permissions) {
 let roles = [];
 for(var i=0;i<user_permissions.length;i++){
    roles.push(user_permissions[i][0])
 }

 return routes.filter(({ permission }) => {
  if(!permission) return true;
  else if(!isArrayWithLength(permission)) return true;
  else return intersection(permission, roles).length;
 });
}