import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
// datamatrix scanner
import { useZxing } from 'react-zxing';

// Redux
import { useSelector } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box, Button, Card } from '@material-ui/core';
import scanner_icon from 'assets/img/scanner_icon.png'
import payout_icon from 'assets/img/payout_icon.png'
import payout_icon_success from 'assets/img/payout_icon_success.png'
import already_paid_icon from 'assets/img/already_paid_icon.png'

import axios from 'axios';
import { ServerConfig } from '../../config';
import { configureConfigHeader } from 'utils/utils';


const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: `#007a9c`,
    padding: '1rem',
    borderRadius: 20,
    boxShadow: theme.shadows[5],
    maxWidth: '900px',
    [theme.breakpoints.up('md')]: {
      padding: '12px 24px 24px 24px',
    }
  },
  headerTitle: {
    fontWeight: 400,
    paddingLeft: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
  bigButton: {
    backgroundColor:'#fff',
    maxWidth: '400px'
    /*marginTop:'10%',
    [theme.breakpoints.down('xs')]: {
      marginTop: '50%'
    }*/
  },
  outer: {
      display: 'table',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
  },
  middle: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  iconAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
  },
  text: {
    maxWidth: '700px',
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(16),
    // textAlign: 'justify',
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(2),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  link: {
    fontWeight: 400,
    fontSize: '1rem',
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:hover,&:focus': {
      color: theme.palette.primary.contrastText,
      background: 'inherit',
    },
  },
  thankyou: {
    color: theme.palette.tertiary.main,
  },
  logo: {
    width: '3.5rem',
    height: 'auto',
    padding: '1px',
    filter: `drop-shadow(2px 2px 3px rgb(0 0 0 / 0.4))`,
  },
  cameraView: {
    position: "fixed",
    top: "0",
    left: "0",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    boxShadow: "none",
    overflow: "none",
    width: "100%",
    padding: "0",
    margin: "0",
    height: "100%",
    minWidth: "100%",
    minHeight: "100%",
    justifyContent: "center",
    zIndex: "1998",
    display:"flex"
  },
  cameraViewButton: {
    backgroundColor: "#900",
    color: 'white',
    position: "fixed",
    top:"100px",
    right:"100px",
    zIndex: "999",
    display:"flex"
  },
  amount: {
	  fontSize: "64px"
  }
}));

export const BarcodeScanner = (props) => {
    const classes = useStyles(props);
    const [result, setResult] = useState("");

    const { ref } = useZxing({

      onResult(result) {
        console.log("READ DATAMATRIX:", result.getText())
        setResult(result.getText());
        props.codeSetter(result.getText());
        props.callback();
      },
    });
  
    return (
      <>
      <div className={classes.cameraView} >
        <video ref={ref} />
        <Button variant="contained" onClick={() => {props.callback(); props.cancel_callback()}} className={classes.cameraViewButton}>&#10006; Close</Button>
        </div>
      </>
    );
  };

function BagScannerCard(props) {
  const { t } = useTranslation();
  const token = useSelector((state) => state.auth.access_token)
  const classes = useStyles(props);
  const customer = useSelector((state) => state.auth.customer);

  const [scannerOpen, setScannerOpen] = useState(0);
  const [datamatrixCode, setDatamatrixCode] = useState("");
  const [message, setMessage] = useState(t('common.please_scan'));
  const [amountDollars, setAmountDollars] = useState(-1.);
  const [success, setSuccess] = useState(false);

  const [uistate, setUistate] = useState("ready-for-scan");

  const submitDatamatrixCode = () => {
    const config = {headers:{'Content-Type':'application/json'}}
    
    if(token){
        config.headers.Authorization = `Bearer ${token}`
    }
    
    const body = JSON.stringify({'task_id': datamatrixCode});

    let ret = axios
                .post(`${ServerConfig.SERVER_URL}/task/cash_out_prepare`, body, config)
                .then(response => {  // all checks passed, show value and allow cash-out
                    console.log(response);
                    setMessage(t("common.value"));
                    setAmountDollars(response.data.redemption_amount_cents/100);
                    setUistate("ready-to-pay-out")
                })
                .catch(error => {  // anything except success (already paid, not found, after 24h, other tenant)
                  console.log(error.response);
                  setMessage(t("error." + error.response.data.message));
                  setUistate("cannot-pay-out")
                });
  };

	const payOut = () => {
    const config = {headers:{'Content-Type':'application/json'}}
	    
    if(token){
		  config.headers.Authorization = `Bearer ${token}`
    }
	    
    const body = JSON.stringify({'task_id': datamatrixCode});

    let ret = axios
                .post(`${ServerConfig.SERVER_URL}/task/cash_out`, body, config)
                .then(response => {
                      setUistate("successfully-paid");
                      setMessage("Success");
                })
                .catch(error => {
                  console.log(error.response);
                  setMessage(t("error." + error.response.data.message));
                  setUistate("cannot-pay-out");
                });
	}


	const reset = () => {
		setMessage(t('common.please_scan'));
		setDatamatrixCode("");
		setAmountDollars(-1.);
	}

	useEffect(() => {
		if(datamatrixCode != ""){
			submitDatamatrixCode();
		}
	}, [datamatrixCode]);


  return (
  <div className={classes.outer}>
    <Box p={2} className={classes.middle}>
	  <Grid container justifyContent="center" style={{'text-align':'center'}}>
	    <Grid item xs={12} justifyContent="center" style={{'text-align':'center'}}>
            <Typography variant="body">
                {message}
            </Typography>
          </Grid>
	  </Grid>

        <Box p={2} style={{ 'width': '100%' }}>
            {scannerOpen == 1 && 
            	<BarcodeScanner callback={() => setScannerOpen(0)} cancel_callback={() => reset()} codeSetter={(code) => {setDatamatrixCode(code);}}/>
            }

            {uistate == "ready-for-scan" &&
                <Button
                    variant="contained"
                    className={classes.bigButton}
                    onClick={() => {setMessage(""); setDatamatrixCode(""); setScannerOpen(1)}}
                    >
                    <Grid container>
                      <Grid item xs={12}>
                        <img src={scanner_icon} style={{'width':'100%', 'marginLeft':'auto', 'marginRight':'auto'}} />
                    </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h2">
                          {t('common.scan')}
                        </Typography>
                    </Grid>
                    </Grid>
              </Button>
            }

            {uistate == "successfully-paid" &&
              <>
                <Button
                    variant="contained"
                    onClick={() => {reset(); setUistate("ready-for-scan")}}
                    className={classes.bigButton}>
                    <Grid container>
                      <Grid item xs={12}>
                        <img src={payout_icon_success} style={{'width':'100%', 'marginLeft':'auto', 'marginRight':'auto'}} />
                      </Grid>
                      <Grid item xs={12}>
                      <Typography variant="h2">
                        {t('common.sucessfullyPaid')}
                      </Typography>
                      </Grid>
                    </Grid>
                </Button>
              </>
            }

	    {(uistate == "cannot-pay-out") &&
			    <>
            <Button
                variant="contained"
                onClick={() => {reset(); setUistate("ready-for-scan");}}
                className={classes.bigButton}
                style={{'backgroundColor':'#900'}}>
                <Grid container>
              <Grid item xs={12}>
                <img src={already_paid_icon} style={{'width':'100%', 'marginLeft':'auto', 'marginRight':'auto'}} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h2" style={{color: "white"}} >
                  {t('common.cancel')}
                </Typography>
              </Grid>
                </Grid>
            </Button>
			    </>
	    }

      {uistate == "ready-to-pay-out" &&
        <>
        <br />
        <Typography color="secondary" variant="h1" className={classes.amount}>
              ${amountDollars.toFixed(2)}
        </Typography>

        <br />

        <Button
            variant="contained"
            onClick={() => payOut()}
            className={classes.bigButton}>
            <Grid container>
          <Grid item xs={12}>
            <img src={payout_icon} style={{'width':'100%', 'marginLeft':'auto', 'marginRight':'auto'}} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2">
              {t('common.markAsPaid')}
            </Typography>
          </Grid>
            </Grid>
        </Button>
        <br/>

        <Button
            variant="contained"
            onClick={() => {reset(); setUistate("ready-for-scan")}}
            style={{'backgroundColor':'#900', 'marginTop':'20px', 'color': 'white' }}>
          <Typography>{t('common.cancel')}</Typography>
        </Button>
        </>
	    }
        </Box>
    </Box>
    </div>
  );
}

export default BagScannerCard;

BagScannerCard.propTypes = {
  children: PropTypes.node,
};
