import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, FormControl, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { setLanguage } from 'redux/actions/auth';

const useStyles = makeStyles((theme) => ({}));

export default function LanguageSelect() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.auth.language);
  const preferredLanguage = useSelector(
    (state) => state.auth.customer?.preferred_language,
  );
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const locales = {
    en: { title: 'English' },
    es: { title: 'Español' },
    fr: { title: 'Français' },
    ru: { title: 'Русский' },
    zh_CN: { title: '简体中文' },
    zh_TW: { title: '中國傳統的' },
  };

  useEffect(() => {
    setSelectedLanguage(language);
  }, [language]);

  useEffect(() => {
    preferredLanguage && setSelectedLanguage(preferredLanguage);
  }, [preferredLanguage]);

  const handleLanguageSelect = (event) => {
    dispatch(setLanguage(event.target.value));
    i18n.changeLanguage(event.target.value);
  };

  return (
    <FormControl>
      <Select
        id="select-language"
        value={selectedLanguage}
        disableUnderline
        renderValue={() => {
          return selectedLanguage;
        }}
        onChange={handleLanguageSelect}
      >
        {Object.keys(locales).map((locale, index) => (
          <MenuItem value={locale} key={index}>
            {locales[locale].title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}