import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from 'App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import { store } from 'redux/storage'
import 'fonts/HelveticaNeueBold.ttf'
import 'fonts/HelveticaNeueLight.ttf'
import 'fonts/HelveticaNeueMedium.ttf'
import 'fonts/HelveticaNeueThin.ttf'
import './i18n.js';

ReactDOM.render(
  <React.Suspense fallback={<div>Loading...</div>}>
      <Provider store={store}>
        <App />
      </Provider>
    </React.Suspense>,
  
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
